<template>
  <div class="div__ejemplo">
    <router-link to="/">
      <BaseButton
        color="btnPrimary"
      >
        Inicio
      </BaseButton> 
    </router-link>
    <br />
    <img
      v-if="isProduct == true"
      class="img__ejemplo"
      src="../../assets/ejemploremito.jpg"
      alt="Remito"
    />
    <img
      v-else
      class="img__ejemplo"
      src="../../assets/ejemplosaldoctacte.jpg"
      alt="Saldo en cuenta corriente"
    />
  </div>
</template>

<script>
import { BaseButton } from "../../components/design-system";
export default {
  components: {
    BaseButton,
  },
  data: (vm) => ({
    isProduct: true,
  }),
  mounted() {
    switch (this.$route.path) {
      case "/EjemploImagenAdquirirSaldo":
        this.isProduct = false;
        break;
      default:
        this.isProduct = true;
        break;
    }
  },
};
</script>

<style scoped>
.div__ejemplo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.routerLink_total {
  background-color: #011f5b;
  color: white !important;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.img__ejemplo {
  box-shadow: 0 0 0.5rem #000000;
}
</style>
